<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <card-component
      class="has-table has-mobile-sort-spaced"
      :title="`Roles ( Total : ${store.total} )`"
      icon="account-multiple"
      header-icon="plus-thick"
      @header-icon-click="addRole"
      :class="[
        'has-table',
        store.total == 0 ? 'has-thead-hidden' : ''
      ]"
    >
      <div slot="right" class="p-2">
        <div class="columns">
          <div class="column is-offset-8 is-4">
            <b-field label="Search" label-position="on-border">
                  <b-input placeholder="Search items..." v-model="filter" @input="loadAsyncData()"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <b-table
        :data="store.items"
        :checkable="checkable"
        :checked-rows.sync="checkedRows"
        :loading="loading"
        :striped="true"
        paginated
        backend-pagination
        :total="store.total"
        :per-page="store.per_page"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort">

        <b-table-column field="name" label="Name" sortable centered v-slot="props">
            {{ props.row.name }}
        </b-table-column>

        <b-table-column field="slug" label="Slug" sortable centered v-slot="props">
            {{ props.row.slug }}
        </b-table-column>

        <b-table-column field="permissions" width="300" label="Permissions" sortable centered v-slot="props">
            <b-taglist>
              <b-tag v-for="per in props.row.permissions" :key="per.value" type="is-danger" closable>
                  {{per.text}}
              </b-tag>
            </b-taglist>
        </b-table-column>

        <b-table-column field="created_at" label="Created Date" sortable centered v-slot="props">
            {{ props.row.created_at }}
        </b-table-column>

        <b-table-column field="updated_at" label="Updated Date" sortable centered v-slot="props">
            {{ props.row.updated_at }}
        </b-table-column>

        <b-table-column custom-key="actions" cell-class="is-actions-cell" centered v-slot="props">
          <div class="buttons is-centered">
            <router-link :to="{name:'role.edit', params: {id: props.row.id}}" class="button is-small is-primary">
              <b-icon icon="account-edit" size="is-small"/>
            </router-link>
            <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
              <b-icon icon="trash-can" size="is-small"/>
            </button>
          </div>
        </b-table-column>
        <section slot="empty" class="section">
          <div class="content has-text-grey has-text-centered">
            <template v-if="store.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large" />
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large" />
              </p>
              <p>Nothing's here&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import ModalBox from '@/components/ModalBox'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RoleTable',
  components: {
    ModalBox,
    CardComponent
  },
  props: {
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      checkedRows: [],
      data: [],
      total: 0,
      loading: false,
      filter: '',
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    ...mapGetters('role', ['store'])
  },
  mounted () {
    this.loadAsyncData()
  },
  methods: {
    ...mapActions('role', ['fetchItems', 'deleteItem']),
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id)
    },
    trashCancel () {
      this.isModalActive = false
    },
    loadAsyncData () {
      const params = {
        filter: this.filter,
        sort_by: this.sortField,
        sort_order: this.sortOrder,
        page: this.page
      }

      this.loading = true
      this.fetchItems(params).then(() => {
        this.loading = false
      })
    },
    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadAsyncData()
    },
    addRole () {
      this.$router.push({ name: 'role.new' })
    }
  }
}
</script>

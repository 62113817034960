<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Users
      <router-link slot="right" :to="{ name: 'user.new' }" class="button">
        New Users
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <user-table :checkable="true"/>
      <notification class="is-info">
        <div>
          <b-icon icon="buffer" custom-size="default" />
          <b>Manage user roles and permissions on the go.</b>&nbsp;
        </div>
      </notification>
      <role-table :checkable="true"/>
      <permission-table :checkable="true"/>
    </section>
  </div>
</template>

<script>
import Notification from '@/components/Notification'
import TitleBar from '@/components/TitleBar'
import UserTable from '@/components/User/UserTable.vue'
import RoleTable from '@/components/User/RoleTable.vue'
import PermissionTable from '@/components/User/PermissionTable.vue'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Users',
  components: {
    HeroBar,
    TitleBar,
    Notification,
    UserTable,
    RoleTable,
    PermissionTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Users']
    }
  }
}
</script>
